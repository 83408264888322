<template>
  <Panel header="Login" id="login-painel">
    <div class="grid p-fluid relative">
      <div class="col-12 p-field">
        <label for="email" class="p-sr-only">E-mail</label>
        <InputText
          id="email"
          type="text"
          placeholder="E-mail"
          v-model="email"
          @change="verificaDistribuidores"
        />
      </div>
      <div class="col-12 p-field" v-if="comDistribuidores">
        <label for="distribuidor" class="p-sr-only">Distribuidor</label>
        <InputText
          id="distribuidor"
          type="text"
          placeholder="Distribuidor (opcional)"
          v-model="distribuidor"
        />
      </div>
      <div class="col-12 p-field">
        <label for="senha" class="p-sr-only">Senha</label>
        <InputText
          id="senha"
          type="password"
          placeholder="Senha"
          v-model="senha"
        />
      </div>
      <div class="col-12 p-field">
        <Button
          type="button"
          label="autenticar"
          icon="pi pi-check"
          @click="login"
        />
      </div>

      <div class="loading absolute top-0 bottom-0 left-0 right-0" v-show="loading"></div>

    </div>
  </Panel>

  <Toast position="top-right" />

</template>

<script>

// import firebase from 'firebase/app';
// import 'firebase/database';
// import 'firebase/auth';

export default {
  data() {
    return {
      comDistribuidores: false,
      loading: false,

      email: "",
      distribuidor: "",
      senha: "",

    };
  },
  methods: {

    verificaDistribuidores: async function(/*e*/) {

      // TODO REVISAR E REFAZER

      // this.comDistribuidores = false;

      // if(e.target.value == "") {  
      //   return;
      // }

      // this.loading = true;

      // const snapshot = await firebase.database().ref("Usuarios").orderByChild('email').equalTo(e.target.value).once("value");

      // if(snapshot.exists()) {
      //   if(typeof Object.values(snapshot.val())[0].distribuidores == "object")
      //     this.comDistribuidores = true;
      // }

      // this.loading = false;

    },

    login: async function () {

      if(this.email == "" || this.senha == "") {
        this.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: "O e-mail e senha precisam ser informados!",
          life: 5000,
        });
        return;
      }

      let senha = this.senha;

      if(this.distribuidor != "") {

        // TODO REVISAR!!!

        // const snapshot = await firebase.database().ref("Usuarios").orderByChild('email').equalTo(this.email).once("value");
        
        // if(snapshot.exists()) {
        //   const empresa = Object.values(snapshot.val())[0];
        //   if(typeof empresa.distribuidores == "object" && typeof empresa.distribuidores[this.distribuidor] == "object") {
        //     if(empresa.distribuidores[this.distribuidor].senha == this.senha) {
        //       senha = empresa.senha;
        //       this.$root.distribuidor = empresa.distribuidores[this.distribuidor];
        //       delete this.$root.distribuidor.senha;
        //       window.localStorage.setItem("VIPDeliveryDistribuidor", this.distribuidor);
        //     } else {
        //       this.$toast.add({
        //         severity: "error",
        //         summary: "ERRO!",
        //         detail: "Senha do Distribuidor inválida!",
        //         life: 5000,
        //       });
        //       return;
        //     }
        //   } else {
        //     this.$toast.add({
        //       severity: "error",
        //       summary: "ERRO!",
        //       detail: "Distribuidor não identificado!",
        //       life: 5000,
        //     });
        //     return;
        //   }
        // }

      }

      const resL = await this.$auth.login(this.email, senha);

      if(resL.success) {

        this.$router.replace("/");

      } else {

        this.$toast.add({
          severity: "error",
          summary: "Atenção!",
          detail: resL.message,
          life: 5000,
        });

      }

    }
  },
};
</script>

<style lang="scss" scoped>

  #login-painel {
    width: 400px;
    position: absolute;
    left: 50%;
    margin-left: -200px;
    top: 50%;
    margin-top: -100px;
  }

  .loading {
    background: rgba(0,0,0,0.4);
  }

</style>